import { FlowApi } from '../../types';
import { I18nNamespace, I18nResource } from '../../types/i18n';

export const getLocaleDatasetResource = async (
  flowAPI: FlowApi,
): Promise<I18nResource> => {
  const getLocaleDatesetResourcesUrl = (locale = 'en', version = '1.983.0') =>
    `https://static.parastorage.com/services/locale-dataset-data/${version}/translations/messages_${locale}.json`;
  const locale = flowAPI.translations.config.language;
  const url = getLocaleDatesetResourcesUrl(locale);
  const { data } = await flowAPI.httpClient
    .request<I18nResource['resources']>({ url, method: 'GET' })
    .catch((exception) => {
      flowAPI.sentry.captureException(exception);
      return { data: {} };
    });

  return {
    lng: locale,
    ns: I18nNamespace.localeDataset,
    resources: data,
  };
};
